import { call, login as loginAPI } from "../api/index";
import { success, error } from "../components/Toast/Toast";

export const login = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "SETLOADING", loading: true });
    const data = await call(loginAPI(formData));
    success("Login successfully");
    dispatch({
      type: "AUTH",
      data: {
        ...data.profile,
        token: data.token,
      },
    });
  } catch (err) {
    error(err?.message || "Something went wrong");
  }
  dispatch({ type: "SETLOADING", loading: false });
};
