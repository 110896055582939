import { createReducer } from "@reduxjs/toolkit";
const isProd = process.env.REACT_APP_ENV === "production";

export const authReducer = createReducer(
  { authData: undefined, authLoading: false },
  {
    AUTH: (state, action) => {
      state.authData = action.data;
      localStorage.setItem("profile", JSON.stringify({ ...action.data }));
      isProd && window.electronAPI.setLoginState(true, action.data);
    },
    LOGOUT: (state) => {
      state.authData = null;
      localStorage.removeItem("profile");
      isProd && window.electronAPI.setLoginState(false);
    },
    SETUSER: (state, action) => {
      state.authData = action.data;
      isProd && window.electronAPI.setLoginState(true, action.data);
    },
    SETNULL: (state) => {
      state.authData = null;
      isProd && window.electronAPI.setLoginState(false);
    },
    SETLOADING: (state, action) => {
      state.authLoading = action.loading;
    },
  }
);

export const timerReducer = createReducer(
  { timer: null },
  {
    STARTTIMER: (state, action) => {
      state.timer = action.data;
      isProd && window.electronAPI.setMonitoring(true, action.data.ts_id);
    },
    SETTIMERTYPE: (state, action) => {
      state.timerType = action.data;
    },
    STOPTIMER: (state) => {
      state.timer = null;
      isProd && window.electronAPI.setMonitoring(false);
    },
  }
);
