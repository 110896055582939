import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_API });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }
  return req;
});

export const call = (callback) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await callback;
      if (data.success) {
        delete data.success;
        resolve(data);
      } else {
        delete data.success;
        reject(data);
      }
    } catch (err) {
      reject("Something went wrong");
    }
  });
};

// Authentication

export const login = (data) => API.post("/auth/login", data);
export const verifyToken = () => API.get("/auth/verify");

export const startTimer = () => API.post("/timer/start");
export const stopTimer = (data) => API.post("/timer/stop", data);
export const getTimer = (data) => API.get("/timer/started");
export const getDashboardData = (data) => API.get("/report/dashboard");
export const getTimesheets = (data) => API.post("/report/timesheet/day", data);
export const getDurationslots = (data) =>
  API.post("/report/durationslots", data);
export const getInactiveslots = (data) =>
  API.post("/report/inactiveslots", data);

export const getTimerType = (data) => API.get("/user/timer/" + data.id);
