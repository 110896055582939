import "./Sidebar.css";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

function Sidebar() {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user_name = JSON.parse(localStorage.getItem("profile")).name;

  useEffect(() => {
    let s = true;
    if (localStorage.getItem("sidebarOpen")) {
      s = localStorage.getItem("sidebarOpen") == "true" ? true : false;
    } else {
      localStorage.setItem("sidebarOpen", true);
    }
    setSidebarOpen(s);
  }, []);

  function handlesidebar(e) {
    e.preventDefault();
    localStorage.setItem("sidebarOpen", !sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  }

  function getClass(tab) {
    return location.pathname.split("/")[1] == tab
      ? "sidebar-item active"
      : "sidebar-item";
  }

  return (
    <div className="sidebar">
      {!sidebarOpen ? (
        <div className="sidebar-open-wrap">
          <div className="sidebar-open-btn" onClick={handlesidebar}>
            <ArrowForwardIosOutlinedIcon />
          </div>
        </div>
      ) : (
        <div className={`sidebar-wrap open`}>
          <div className="current-workspace">
            <div className="current-workspace-icon">{user_name[0]}</div>
            <span>{user_name}</span>
            <div className="current-workspace-show-btn" onClick={handlesidebar}>
              <ArrowBackIosOutlinedIcon />
            </div>
          </div>
          <div className="sidebar-item-wrap">
            <Link to={{ pathname: `/` }} className={getClass("")}>
              <DashboardCustomizeOutlinedIcon />
              <span>Dashboard</span>
            </Link>
            <div className="sidebar-item-heading">
              <span>ANALYZE</span>
            </div>
            <Link to={{ pathname: `/report` }} className={getClass("report")}>
              <AssessmentOutlinedIcon />
              <span>Report</span>
            </Link>
            <div className="sidebar-item-heading">
              <span>MANAGE</span>
            </div>
            <Link
              to={{ pathname: `/timesheet` }}
              className={getClass("timesheet")}
            >
              <TimerOutlinedIcon />
              <span>Timesheet</span>
            </Link>
            <Link
              to={{ pathname: `/settings` }}
              className={getClass("settings")}
            >
              <SettingsOutlinedIcon />
              <span>Settings</span>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
