import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Image from "../../images/404.svg";
import "./404.css";

export default function E404() {
  return (
    <div className="d-flex flex-column align-items-center gap-5 my-3">
      <h1>Sorry, page not found!</h1>
      <img
        style={{ width: "95%", maxWidth: "400px" }}
        src={Image}
        alt=""
        className="img-404"
      />
      <Link to={{ pathname: "/" }}>
        <Button className="text-capitalize" variant="contained" size="large">
          Go To Dashboard
        </Button>
      </Link>
    </div>
  );
}
