import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { BtnLoading } from "../Loading/Loading";
import { start, stop } from "../../actions/timer";
import { call, getTimer } from "../../api/index";
import { error, success } from "../Toast/Toast";
import { useDispatch, useSelector } from "react-redux";

function Timer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const timerData = useSelector((state) => state.timerReducer);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState({ h: "00", m: "00", s: "00" });
  const [tInterval, setTInterval] = useState("");
  const [timerStatus, setTimerStatus] = useState(false);

  useEffect(() => {
    if (timerData.timer?.startAt) {
      startTimer(timerData.timer.duration);
    } else {
      if (timerStatus) {
        stopTimer();
      }
    }
  }, [timerData]);

  useEffect(() => {
    fetchTimer();
  }, [history.location]);

  const fetchTimer = async () => {
    setLoading(true);
    try {
      const data = await call(getTimer());
      dispatch({
        type: "STARTTIMER",
        data: {
          duration: data.duration,
          startAt: data.start_at,
          ts_id: data.ts_id,
        },
      });
    } catch (err) {
    } finally {
      setInterval(() => {
        setLoading(false);
      }, 200);
    }
  };

  const handleTimer = async () => {
    try {
      timerStatus ? dispatch(stop(timerData.timer)) : dispatch(start());
    } catch (err) {
      error("Something wrong");
    }
  };

  const startTimer = (duration) => {
    setTimerStatus(true);
    let h = parseInt(duration.split(":")[0]);
    let m = parseInt(duration.split(":")[1]);
    let s = parseInt(duration.split(":")[2]);

    clearInterval(tInterval);

    const t = setInterval(function () {
      if (s === 59) {
        s = 0;
        if (m === 59) {
          m = 0;
          h++;
        } else {
          m++;
        }
      } else {
        s++;
      }
      setTimer({
        h: h < 10 ? "0" + h : h,
        m: m < 10 ? "0" + m : m,
        s: s < 10 ? "0" + s : s,
      });
    }, 1000);

    setTInterval(t);
  };

  const stopTimer = () => {
    setTimer({ h: "00", m: "00", s: "00" });
    clearInterval(tInterval);
    setTimerStatus(false);
  };

  return loading ? (
    <BtnLoading color="#0d3f66" />
  ) : (
    <>
      <h5>
        <div>{timer.h}</div>:<div>{timer.m}</div>:<div>{timer.s}</div>
      </h5>
      {timerData.timerType === "Manual" &&
        (!timerStatus ? (
          <Button
            onClick={handleTimer}
            variant="contained"
            style={{ background: "#0d3f66" }}
            size="large"
          >
            START
          </Button>
        ) : (
          <Button
            onClick={handleTimer}
            variant="contained"
            color="error"
            size="large"
          >
            STOP
          </Button>
        ))}
    </>
  );
}

export default Timer;
