import "./Dashboard.css";
import React, { useEffect, useState } from "react";
import Timer from "./Timer";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { BtnLoading } from "../Loading/Loading";
import { call, getDashboardData } from "../../api";
import { useHistory } from "react-router-dom";

export default function Dashboard() {
  const history = useHistory();
  const [weekLoading, setWeekLoading] = useState(true);
  const [monthLoading, setMonthLoading] = useState(true);
  const [thisWeekTimer, setThisWeekTimer] = useState("");
  const [thisMonthTimer, setThisMonthTimer] = useState("");

  useEffect(() => {
    fetchData();
  }, [history.location]);

  const fetchData = async () => {
    try {
      const { data } = await call(getDashboardData());
      setThisWeekTimer(
        `${data.week[0] ? data.week[0].duration.h : 0}h ${
          data.week[0] ? data.week[0].duration.i : 0
        }m`
      );
      setThisMonthTimer(
        `${data.month[0] ? data.month[0].duration.h : 0}h ${
          data.month[0] ? data.month[0].duration.i : 0
        }m`
      );
    } catch (err) {
    } finally {
      setWeekLoading(false);
      setMonthLoading(false);
    }
  };
  return (
    <div className="dashboard">
      <div className="dashboard-wrap">
        <div className="dsb-card">
          <div className="dsb-card-header">
            <div className="dsb-card-icon" style={{ background: "#3d3d44" }}>
              <AccessTimeOutlinedIcon />
            </div>
            <h6 className="title-underline">Today</h6>
          </div>
          <div className="dsb-card-body">
            <div className="timer-wrap">
              <Timer />
            </div>
          </div>
        </div>
        <div className="dsb-card">
          <div className="dsb-card-header">
            <div className="dsb-card-icon" style={{ background: "#b51b52" }}>
              <AccessTimeOutlinedIcon />
            </div>
            <h6 className="title-underline">This Week</h6>
          </div>
          <div className="dsb-card-body">
            {weekLoading ? (
              <BtnLoading color="#0d3f66" />
            ) : (
              <h5 className="p-2 pb-0 text-center">{thisWeekTimer}</h5>
            )}
          </div>
        </div>
        <div className="dsb-card">
          <div className="dsb-card-header">
            <div className="dsb-card-icon" style={{ background: "#bf7417" }}>
              <AccessTimeOutlinedIcon />
            </div>
            <h6 className="title-underline">This Month</h6>
          </div>
          <div className="dsb-card-body">
            {monthLoading ? (
              <BtnLoading color="#0d3f66" />
            ) : (
              <h5 className="p-2 pb-0 text-center">{thisMonthTimer}</h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
