import "./Navbar.css";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
// import Logo from "../../images/404.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { success } from "../Toast/Toast";
import Alert from "../Alert/Alert";

function Navbar() {
  const history = useHistory();
  const location = useLocation();
  const { authData } = useSelector((state) => state.authReducer);

  !authData && history.push("/login");

  const [userData, setUserData] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const title = location.pathname.split("/")[1].replace("-", " ");
    document.title =
      "Tracker | " + (title ? title[0].toUpperCase() + title.slice(1) : "Home");
  }, [location]);

  const openLogout = () => {
    setShowAlert(true);
  };

  const keepLogin = () => {
    setShowAlert(false);
  };
  const logout = () => {
    dispatch({ type: "LOGOUT" });
    setUserData(null);
    history.push({ pathname: "/login" });
    setShowAlert(false);
  };

  const openAccoutPopup = () => {
    setAccountModal(true);
  };

  const checkEvent = (e) => {
    const menuBtn = document.querySelector(".profile-pic");
    const accountWrap = document.querySelector(".account-pop");
    if (menuBtn) {
      if (!menuBtn.contains(e.target)) {
        if (!accountWrap.classList.contains("d-none")) {
          accountWrap.classList.add("d-none");
        }
      } else {
        !accountWrap.classList.contains("d-none")
          ? accountWrap.classList.add("d-none")
          : accountWrap.classList.remove("d-none");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", checkEvent);
    return () => document.removeEventListener("mouseup", checkEvent);
  }, []);

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-wrap">
          <Link to={{ pathname: "/" }}>
            {/* <img src={Logo} className="Navbar-logo" alt="navbar logo" /> */}
            <div className="Navbar-logo-text">
              <span>T</span>racker
            </div>
          </Link>
          <div className="Navbar-right-btns">
            <Link to={{ pathname: "/" }}>
              <Button className="Nav-btns">Home</Button>
            </Link>
            {authData ? (
              <>
                <div className="profile-wrap">
                  <button className="profile-pic" onClick={openAccoutPopup}>
                    {authData.name[0]}
                  </button>
                  <div className="account-pop d-none">
                    <div className="account-pop-header">Account</div>
                    <div className="account-pop-body">
                      <Link to={{ pathname: "/profile" }}>
                        <div className="account-pop-item">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                          Profile
                        </div>
                      </Link>
                      <Link to={{ pathname: "/activity" }}>
                        <div className="account-pop-item">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="4" y1="21" x2="4" y2="14"></line>
                            <line x1="4" y1="10" x2="4" y2="3"></line>
                            <line x1="12" y1="21" x2="12" y2="12"></line>
                            <line x1="12" y1="8" x2="12" y2="3"></line>
                            <line x1="20" y1="21" x2="20" y2="16"></line>
                            <line x1="20" y1="12" x2="20" y2="3"></line>
                            <line x1="1" y1="14" x2="7" y2="14"></line>
                            <line x1="9" y1="8" x2="15" y2="8"></line>
                            <line x1="17" y1="16" x2="23" y2="16"></line>
                          </svg>
                          Activity
                        </div>
                      </Link>
                      <Link to={{ pathname: "/settings" }}>
                        <div className="account-pop-item">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="12" r="3"></circle>
                            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                          </svg>
                          Settings
                        </div>
                      </Link>
                      <div className="logout-wrap">
                        <div
                          className="account-pop-item logout"
                          onClick={openLogout}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M10 3H6a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h4M16 17l5-5-5-5M19.8 12H9" />
                          </svg>
                          Logout
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              authData === null && (
                <Link to={{ pathname: "/login" }}>
                  <Button
                    className="Nav-btns"
                    variant="contained"
                    color="secondary"
                  >
                    Login
                  </Button>
                </Link>
              )
            )}
          </div>
        </div>
      </div>
      <Alert
        open={showAlert}
        width={500}
        animation="scale"
        data={{
          title: "Are You Sure! Want to Logout?",
          icon: "logout",
          buttons: [
            {
              type: "default",
              text: "No",
              callback: keepLogin,
            },
            {
              class: "red",
              type: "error",
              text: "Yes",
              callback: logout,
            },
          ],
        }}
      />
    </>
  );
}

export default Navbar;
