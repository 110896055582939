import { configureStore } from "@reduxjs/toolkit";
import { authReducer, timerReducer } from "./reducers";

const store = configureStore({
  reducer: {
    authReducer,
    timerReducer,
  },
});

export default store;
