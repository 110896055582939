import "./../Timesheet/Timesheet.css";
import React, { useEffect, useState } from "react";
import { call, getTimesheets } from "../../api";
import utils from "../../utils";
import MUIDataTable from "mui-datatables";
import { Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import InActive from "./InActive";
import Duration from "./duration";

function Index() {
  const [timesheets, setTimesheets] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(currentDate + " " + "00:00:00");
  const [endDate, setEndDate] = useState(currentDate + " " + "23:59:59");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("profile")).id
  );
  const [durationData, setDurationData] = useState("");
  const [inactiveData, setInactiveData] = useState("");

  const fetchTimesheets = async (start, end) => {
    try {
      setStartDate(start);
      setEndDate(end);

      const res = await call(getTimesheets({ userId: user, start, end }));
      setTimesheets(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchTimesheets(startDate, endDate);
  }, []);

  const dateChange = (date) => {
    setCurrentDate(moment(date.$d).format("YYYY-MM-DD"));
    fetchTimesheets(
      moment(date.$d).format("YYYY-MM-DD") + " " + "00:00:00",
      moment(date.$d).format("YYYY-MM-DD") + " " + "23:59:59"
    );
  };

  const handleAddTimesheet = () => {};

  const showDuration = (row) => {
    setDurationData(timesheets[row].ts.join(","));
  };

  const hideDuration = () => {
    setDurationData("");
  };

  const showInactive = (row) => {
    setInactiveData(timesheets[row].ts.join(","));
  };

  const hideInactive = () => {
    setInactiveData("");
  };

  const columns = [
    {
      name: "start_at",
      label: "Start time",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "end_at",
      label: "End time",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, data) => (
          <span
            className="cursor-pointer"
            onClick={() => showDuration(data.rowIndex)}
          >
            {value.h}h {value.i}m {value.s}s
          </span>
        ),
      },
    },
    {
      name: "active",
      label: "Active Duration",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div>
            {value.h}h {value.i}m {value.s}s
          </div>
        ),
      },
    },
    {
      name: "inactive",
      label: "Inactive Duration",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, data) => (
          <span
            className="cursor-pointer"
            onClick={() => showInactive(data.rowIndex)}
          >
            {value.h}h {value.i}m {value.s}s
          </span>
        ),
      },
    },
  ];

  const options = {
    filterType: "multiselect",
    download: false,
    print: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    filter: false,
  };

  return (
    <div className="timesheet">
      <div className="timesheet-header">
        <h5 className="title-underline">Timesheets</h5>
        <Button
          onClick={handleAddTimesheet}
          variant="contained"
          style={{ background: "#0d3f66", textTransform: "capitalize" }}
          size="medium"
          startIcon={<AddOutlinedIcon />}
        >
          Add Timesheet
        </Button>
      </div>
      <div className="moni-d-main">
        <div className="moni-d-header">
          <div className="filter-wrap">
            <h6>Filter :</h6>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={currentDate}
                onChange={(date) => dateChange(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="moni-data">
          <MUIDataTable
            className="m-2"
            data={timesheets}
            columns={columns}
            options={options}
          />
        </div>
      </div>
      {durationData && (
        <Duration ts={durationData} hideDuration={hideDuration} />
      )}
      {inactiveData && (
        <InActive ts={inactiveData} hideInactive={hideInactive} />
      )}
    </div>
  );
}

export default Index;
