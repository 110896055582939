import "./App.css";
import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { call, getTimer, getTimerType, verifyToken } from "./api/index";

import { BtnLoading } from "./components/Loading/Loading";
import Navbar from "./components/Navbar/Navbar";
import E404 from "./components/Error/404";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Auth/Login";
import Test from "./components/Test/Index";
import { start, stop } from "./actions/timer";
import Sidebar from "./components/Sidebar/Sidebar";
import Timesheet from "./components/Timesheet/Timesheet";

const AppRouter = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authData } = useSelector((state) => state.authReducer);

  const checkTokenValid = async () => {
    if (JSON.parse(localStorage.getItem("profile"))) {
      if (!authData) {
        try {
          const token = JSON.parse(localStorage.getItem("profile")).token;
          await call(verifyToken({ token }));
          dispatch({
            type: "SETUSER",
            data: JSON.parse(localStorage.getItem("profile")),
          });
        } catch (err) {
          dispatch({ type: "SETNULL" });
          history.push("/login");
        }
      }
    } else {
      dispatch({ type: "SETNULL" });
      history.push("/login");
    }
  };

  const fetchSetting = async () => {
    try {
      const id = JSON.parse(localStorage.getItem("profile")).id;
      const r = await call(getTimerType({ id }));
      dispatch({
        type: "SETTIMERTYPE",
        data: r.timer,
      });
      if (r.timer === "Automatic") {
        dispatch(start());
      }
    } catch (err) {}
  };

  const initWinListener = () => {
    window.onmessage = (event) => {
      if (event.source === window && event.data === "initCommunication") {
        const [port] = event.ports;
        port.onmessage = (event) => {
          if (event.data?.monitoringStatus) {
            event.data.monitoringStatus === "start"
              ? dispatch(start())
              : dispatch(stop({ ts_id: event.data?.ts_id }));
          }
        };
      }
    };
  };

  useEffect(() => {
    checkTokenValid();
    authData && fetchSetting();
  }, [authData]);

  useEffect(() => {
    initWinListener();
  }, []);

  return (
    <div className="app">
      {authData === undefined ? (
        <BtnLoading color="purple" />
      ) : authData ? (
        <>
          <Navbar />
          <div className="app-wrap">
            <Sidebar />
            <div className="app-content">
              <Switch>
                <Route exact path="/">
                  <Dashboard />
                </Route>
                <Route exact path="/timesheet">
                  <Timesheet />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/test">
                  <Test />
                </Route>
                <Route>
                  <E404 />
                </Route>
              </Switch>
            </div>
          </div>
        </>
      ) : (
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route>
            <E404 />
          </Route>
        </Switch>
      )}
      <ToastContainer pauseOnFocusLoss={false} />
    </div>
  );
};

export default AppRouter;
