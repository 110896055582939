import { call, startTimer, stopTimer } from "../api";
import { success, error } from "../components/Toast/Toast";

export const start = () => async (dispatch) => {
  try {
    const data = await call(startTimer());
    if (!data.alreadyStarted) {
      dispatch({
        type: "STARTTIMER",
        data: {
          duration: "00:00:00",
          startAt: data.start_at,
          ts_id: data.ts_id,
        },
      });
      success("Timer started successfully");
    }
  } catch (err) {
    error(err?.message || "Something went wrong, when start timer");
  }
};

export const stop = (formData) => async (dispatch) => {
  try {
    const data = await call(stopTimer(formData));
    dispatch({
      type: "STOPTIMER",
    });
    success("Timer ended successfully");
  } catch (err) {
    error(err?.message || "Something went wrong, when stop timer");
  }
};
