import "./../Timesheet/Timesheet.css";
import React, { useEffect, useState } from "react";
import { call, getTimesheets } from "../../api";
import utils from "../../utils";
import MUIDataTable from "mui-datatables";
import { Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";

function Index() {
  const [timesheets, setTimesheets] = useState([]);
  const [startDate, setStartDate] = useState(
    utils.ConvertDate(new Date()).date + " " + "00:00:00"
  );
  const [endDate, setEndDate] = useState(
    utils.ConvertDate(new Date()).date + " " + "23:59:59"
  );
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("profile")).id
  );
  const fetchTimesheets = async (start, end) => {
    try {
      setStartDate(start);
      setEndDate(end);

      const res = await call(getTimesheets({ userId: user, start, end }));

      setTimesheets(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchTimesheets(startDate, endDate);
  }, []);

  const dateChange = (date) => {
    fetchTimesheets(
      utils.ConvertDate(date[0]._d).date + " " + "00:00:00",
      utils.ConvertDate(date[1]._d).date + " " + "23:59:59"
    );
  };

  const [value, setValue] = React.useState([null, null]);

  useEffect(() => {
    console.log(value);
  }, [value]);

  function getWeeksAfter(date, amount) {
    return date ? date.add(amount, "week") : undefined;
  }

  const handleAddTimesheet = () => {};
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const data = [
    { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
    { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
    { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
    {
      name: "James Houston",
      company: "Test Corp",
      city: "Dallas",
      state: "TX",
    },
  ];

  const options = {
    filterType: "multiselect",
    download: false,
    print: false,
  };

  return (
    <div className="timesheet">
      <div className="timesheet-header">
        <h5 className="title-underline">Timesheets</h5>
        <Button
          onClick={handleAddTimesheet}
          variant="contained"
          style={{ background: "#0d3f66", textTransform: "capitalize" }}
          size="medium"
          startIcon={<AddOutlinedIcon />}
        >
          Add Timesheet
        </Button>
      </div>
      <div className="moni-d-main">
        <div className="moni-d-header">
          <div className="filter-wrap">
            Filter:{" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="moni-data">
          <MUIDataTable
            className="m-2"
            data={data}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
