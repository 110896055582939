import "./Timesheet";
import React, { useEffect, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { call, getDurationslots } from "../../api/index";
import MUIDataTable from "mui-datatables";
import { BtnLoading } from "../Loading/Loading";

function Duration(props) {
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSlots = async () => {
    try {
      const res = await call(getDurationslots({ ts: props.ts }));
      setSlots(res.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSlots();
    document.addEventListener("keydown", handleKey);
    return () => document.removeEventListener("keydown", handleKey);
  }, []);

  const handleKey = (e) => {
    e.key === "Escape" && props.hideDuration();
  };

  const columns = [
    {
      name: "start_at",
      label: "Start time",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "end_at",
      label: "End time",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
  ];

  const options = {
    filterType: "multiselect",
    download: false,
    print: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    filter: false,
  };

  return (
    <div className="inactive-detail-popup">
      <div className="inactive-p-wrap">
        <div className="p-close-btn" onClick={props.hideDuration}>
          <CloseOutlinedIcon />
        </div>
        <div className="inactive-p-header">
          <h4>Timesheet Breakup</h4>
        </div>
        <div className="inactive-p-body">
          {loading ? (
            <div className="position-relative p-5">
              <BtnLoading />
            </div>
          ) : (
            <MUIDataTable
              className="mui-popup-datatable"
              data={slots}
              columns={columns}
              options={options}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Duration;
