import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./LoginRegister.css";
import Button from "@mui/material/Button";
import { BtnLoading } from "../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { TextField } from "@mui/material";
import { error } from "../Toast/Toast";

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authData, authLoading } = useSelector((state) => state.authReducer);

  useEffect(() => {
    authData && history.push("/");
  }, [authData]);

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [visibility, setVisibility] = useState(false);

  const handleSubmit = () => {
    if (!email || !pass) {
      return error("Please enter your credentials");
    }
    dispatch(login({ email: email, password: pass }));
  };

  const handleShowPassword = () => {
    setVisibility(!visibility);
  };

  return (
    <div className="login">
      <div className="form-container">
        <div className="form-content">
          <form className="signin-form">
            <h2 className="form-title">Log in to your account</h2>
            <div className="w-100">
              <div className="l-form-group p-2">
                <TextField
                  className="w-100"
                  label="Email ID"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="l-form-group position-relative p-2">
                <TextField
                  type={visibility ? "text" : "password"}
                  className="text-field login-field w-100"
                  label="Password"
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />

                {visibility ? (
                  <VisibilityOffOutlinedIcon
                    className="pass-handle"
                    onClick={handleShowPassword}
                  />
                ) : (
                  <VisibilityOutlinedIcon
                    className="pass-handle"
                    onClick={handleShowPassword}
                  />
                )}
              </div>
              <div className="l-form-group form-button p-2">
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="w-100 text-capitalize position-relative"
                  disabled={authLoading}
                >
                  Sign In
                  {authLoading && <BtnLoading color="#fff" />}
                </Button>
              </div>
              <div className="forgot-link">
                <Link to={{ pathname: "/forgot" }}>Forgot password?</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
